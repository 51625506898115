exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-announcement-annual-report-tsx": () => import("./../../../src/pages/announcement/annual-report.tsx" /* webpackChunkName: "component---src-pages-announcement-annual-report-tsx" */),
  "component---src-pages-announcement-news-tsx": () => import("./../../../src/pages/announcement/news.tsx" /* webpackChunkName: "component---src-pages-announcement-news-tsx" */),
  "component---src-pages-announcement-notice-tsx": () => import("./../../../src/pages/announcement/notice.tsx" /* webpackChunkName: "component---src-pages-announcement-notice-tsx" */),
  "component---src-pages-annual-donation-index-tsx": () => import("./../../../src/pages/annual-donation/index.tsx" /* webpackChunkName: "component---src-pages-annual-donation-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-policy-agreement-tsx": () => import("./../../../src/pages/policy/agreement.tsx" /* webpackChunkName: "component---src-pages-policy-agreement-tsx" */),
  "component---src-pages-policy-privacy-tsx": () => import("./../../../src/pages/policy/privacy.tsx" /* webpackChunkName: "component---src-pages-policy-privacy-tsx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-1-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/1.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-1-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-10-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/10.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-10-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-11-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/11.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-11-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-12-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/12.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-12-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-13-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/13.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-13-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-14-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/14.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-14-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-15-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/15.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-15-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-16-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/16.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-16-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-17-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/17.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-17-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-18-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/18.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-18-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-19-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/19.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-19-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-2-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/2.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-2-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-20-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/20.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-20-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-21-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/21.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-21-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-22-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/22.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-22-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-23-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/23.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-23-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-24-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/24.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-24-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-25-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/25.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-25-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-26-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/26.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-26-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-27-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/27.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-27-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-28-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/28.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-28-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-29-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/29.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-29-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-3-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/3.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-3-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-30-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/30.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-30-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-31-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/31.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-31-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-32-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/32.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-32-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-33-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/33.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-33-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-34-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/34.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-34-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-35-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/35.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-35-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-36-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/36.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-36-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-37-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/37.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-37-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-38-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/38.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-38-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-39-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/39.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-39-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-4-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/4.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-4-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-40-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/40.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-40-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-41-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/41.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-41-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-42-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/42.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-42-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-43-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/43.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-43-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-44-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/44.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-44-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-45-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/45.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-45-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-46-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/46.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-46-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-47-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/47.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-47-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-48-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/48.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-48-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-49-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/49.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-49-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-5-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/5.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-5-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-50-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/50.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-50-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-51-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/51.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-51-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-52-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/52.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-52-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-53-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/53.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-53-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-54-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/54.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-54-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-55-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/55.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-55-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-6-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/6.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-6-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-7-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/7.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-7-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-8-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/8.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-8-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-9-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/announcement/notice/9.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-announcement-notice-9-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-annual-donation-1-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/annual-donation/1.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-annual-donation-1-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-annual-donation-2-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/annual-donation/2.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-annual-donation-2-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-annual-donation-3-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/annual-donation/3.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-annual-donation-3-mdx" */),
  "component---src-templates-announcement-notice-tsx-content-file-path-content-annual-donation-4-mdx": () => import("./../../../src/templates/announcement/notice.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/annual-donation/4.mdx" /* webpackChunkName: "component---src-templates-announcement-notice-tsx-content-file-path-content-annual-donation-4-mdx" */),
  "component---src-templates-business-business-tsx-content-file-path-content-business-free-medical-service-free-medical-service-index-mdx": () => import("./../../../src/templates/business/business.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/business/free-medical-service/free-medical-service/index.mdx" /* webpackChunkName: "component---src-templates-business-business-tsx-content-file-path-content-business-free-medical-service-free-medical-service-index-mdx" */),
  "component---src-templates-business-business-tsx-content-file-path-content-business-free-medical-service-health-visits-index-mdx": () => import("./../../../src/templates/business/business.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/business/free-medical-service/health-visits/index.mdx" /* webpackChunkName: "component---src-templates-business-business-tsx-content-file-path-content-business-free-medical-service-health-visits-index-mdx" */),
  "component---src-templates-business-business-tsx-content-file-path-content-business-global-health-global-health-care-index-mdx": () => import("./../../../src/templates/business/business.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/business/global-health/global-health-care/index.mdx" /* webpackChunkName: "component---src-templates-business-business-tsx-content-file-path-content-business-global-health-global-health-care-index-mdx" */),
  "component---src-templates-business-business-tsx-content-file-path-content-business-global-health-refugees-children-education-index-mdx": () => import("./../../../src/templates/business/business.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/business/global-health/refugees-children-education/index.mdx" /* webpackChunkName: "component---src-templates-business-business-tsx-content-file-path-content-business-global-health-refugees-children-education-index-mdx" */),
  "component---src-templates-business-business-tsx-content-file-path-content-business-health-education-adolescents-index-mdx": () => import("./../../../src/templates/business/business.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/business/health-education/adolescents/index.mdx" /* webpackChunkName: "component---src-templates-business-business-tsx-content-file-path-content-business-health-education-adolescents-index-mdx" */),
  "component---src-templates-business-business-tsx-content-file-path-content-business-health-education-children-index-mdx": () => import("./../../../src/templates/business/business.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/business/health-education/children/index.mdx" /* webpackChunkName: "component---src-templates-business-business-tsx-content-file-path-content-business-health-education-children-index-mdx" */),
  "component---src-templates-business-other-tsx-content-file-path-content-business-other-index-mdx": () => import("./../../../src/templates/business/other.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/business/other/index.mdx" /* webpackChunkName: "component---src-templates-business-other-tsx-content-file-path-content-business-other-index-mdx" */),
  "component---src-templates-company-corporation-tsx-content-file-path-content-company-corporation-index-mdx": () => import("./../../../src/templates/company/corporation.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/company/corporation/index.mdx" /* webpackChunkName: "component---src-templates-company-corporation-tsx-content-file-path-content-company-corporation-index-mdx" */),
  "component---src-templates-company-department-tsx-content-file-path-content-company-department-index-mdx": () => import("./../../../src/templates/company/department.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/company/department/index.mdx" /* webpackChunkName: "component---src-templates-company-department-tsx-content-file-path-content-company-department-index-mdx" */),
  "component---src-templates-company-greeting-tsx-content-file-path-content-company-greeting-index-mdx": () => import("./../../../src/templates/company/greeting.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/company/greeting/index.mdx" /* webpackChunkName: "component---src-templates-company-greeting-tsx-content-file-path-content-company-greeting-index-mdx" */),
  "component---src-templates-company-history-tsx-content-file-path-content-company-history-index-mdx": () => import("./../../../src/templates/company/history.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/company/history/index.mdx" /* webpackChunkName: "component---src-templates-company-history-tsx-content-file-path-content-company-history-index-mdx" */),
  "component---src-templates-cooperation-companies-tsx-content-file-path-content-cooperation-companies-index-mdx": () => import("./../../../src/templates/cooperation/companies.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/cooperation/companies/index.mdx" /* webpackChunkName: "component---src-templates-cooperation-companies-tsx-content-file-path-content-cooperation-companies-index-mdx" */),
  "component---src-templates-cooperation-partners-tsx-content-file-path-content-cooperation-partners-index-mdx": () => import("./../../../src/templates/cooperation/partners.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/cooperation/partners/index.mdx" /* webpackChunkName: "component---src-templates-cooperation-partners-tsx-content-file-path-content-cooperation-partners-index-mdx" */),
  "component---src-templates-recruit-culture-tsx-content-file-path-content-recruit-culture-index-mdx": () => import("./../../../src/templates/recruit/culture.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/recruit/culture/index.mdx" /* webpackChunkName: "component---src-templates-recruit-culture-tsx-content-file-path-content-recruit-culture-index-mdx" */),
  "component---src-templates-recruit-organization-tsx-content-file-path-content-recruit-organization-business-index-mdx": () => import("./../../../src/templates/recruit/organization.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/recruit/organization/business/index.mdx" /* webpackChunkName: "component---src-templates-recruit-organization-tsx-content-file-path-content-recruit-organization-business-index-mdx" */),
  "component---src-templates-recruit-organization-tsx-content-file-path-content-recruit-organization-management-index-mdx": () => import("./../../../src/templates/recruit/organization.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/recruit/organization/management/index.mdx" /* webpackChunkName: "component---src-templates-recruit-organization-tsx-content-file-path-content-recruit-organization-management-index-mdx" */),
  "component---src-templates-recruit-recruit-guide-tsx-content-file-path-content-recruit-recruit-guide-index-mdx": () => import("./../../../src/templates/recruit/recruit-guide.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/recruit/recruit-guide/index.mdx" /* webpackChunkName: "component---src-templates-recruit-recruit-guide-tsx-content-file-path-content-recruit-recruit-guide-index-mdx" */),
  "component---src-templates-sponsor-sponsor-guide-tsx-content-file-path-content-sponsor-sponsor-guide-index-mdx": () => import("./../../../src/templates/sponsor/sponsor-guide.tsx?__contentFilePath=/home/runner/work/homepage/homepage/content/sponsor/sponsor-guide/index.mdx" /* webpackChunkName: "component---src-templates-sponsor-sponsor-guide-tsx-content-file-path-content-sponsor-sponsor-guide-index-mdx" */)
}

